@config "../../../tailwind.config.ts";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-size-multiplier: 1;
}
body {
  @apply bg-site-bkg;
  @apply h-screen;
  font-size: calc(var(--font-size-multiplier) * 16px);
}
#__next {
  @apply h-full;
}
